import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as microsoftTeams from '@microsoft/teams-js'

export const useAuth = () => {
  const getCreds = () => {
    return JSON.parse(localStorage.getItem('authCreds'))
  }

  const saveAuthCreds = (creds) => {
    localStorage.setItem('authCreds', JSON.stringify(creds))
    setAuthCreds(creds)
  }

  const [authCreds, setAuthCreds] = useState(getCreds())

  const handleAuthError = (reason) => {
    if (reason == 'CancelledByUser') {
      // Yes, they spelled "cancel" wrong.
      console.log('Canceled')
    } else {
      console.log('Auth Error', reason)
    }
  }

  const openOAuthFlow = () => {
    // https://docs.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-tab-aad
    // This should move to its own page so we can use the callback flow documented above ^
    const state = uuidv4()
    // Needed to prevent Man-in-the-middle attacks
    // See the OAuthCallback component for usage
    localStorage.setItem('simple.state', state)

    microsoftTeams.authentication.authenticate({
      url: `${window.location.origin}/oauth/authorize?state=${state}`,
      width: 530,
      height: 650,
      successCallback: function (result) {
        saveAuthCreds(result)
      },
      failureCallback: function (reason) {
        handleAuthError(reason)
      },
    })
  }

  return {
    openOAuthFlow,
    accessToken: authCreds?.access_token
  }
}