import React from 'react'
import { useAuth } from './useAuth'

export const MyPowtoonsTab = () => {
  // This might be better in App.js
  const { accessToken, openOAuthFlow } = useAuth()

  if (accessToken) {
    return (
      <iframe
        title='My Powtoons'
        src={`${process.env.REACT_APP_POWTOON_API_BASE}/views/integrations/ms-teams/my-powtoons-personal-tab`}
      ></iframe>
    )
  } else {
    return (
      <button onClick={openOAuthFlow}>Log into Powtoon</button>
    )
  }
}
