import React from 'react'
import { useAuth } from './useAuth'

export const CreateTab = () => {
  const { accessToken, openOAuthFlow } = useAuth()

  if (accessToken) {
    return (
      <iframe
        title='Create a Powtoon'
        src={`${process.env.REACT_APP_POWTOON_API_BASE}/views/integrations/ms-teams/scratch-or-template`}
      ></iframe>
    )
  } else {
    return (
      <button onClick={openOAuthFlow}>Log into Powtoon</button>
    )
  }
}
