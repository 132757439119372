export const oauthUrl = (state) => {
  const oauth_url = process.env.REACT_APP_OAUTH_URL
  const client_id = process.env.REACT_APP_OAUTH_CLIENT_ID
  const oauth_redirect_url = process.env.REACT_APP_OAUTH_REDIRECT_URL
  const url = `${oauth_url}?client_id=${client_id}&scope=read&response_type=token&redirect_uri=${oauth_redirect_url}&state=${state}`
  console.log(
    "url",
    url
  )
  return url
}
