import React, { useState, useEffect } from 'react'
import * as microsoftTeams from '@microsoft/teams-js'

// If powtoon.com had the micrsoftTeams SDK, this is how it would invoke a task module.
// powtoon.com will first need to initialize the SDK with `microsoftTeams.initialize()`
export const InsideIframe = () => {
  const taskInfo = {
    title: 'test task',
    height: 1000,
    width: 640,
    url: `${window.location.origin}/task-module`,

    // This is required for the bot to receive the submit!!!
    completionBotId: process.env.REACT_APP_BOT_ID,
  }

  const startTask = () => microsoftTeams.tasks.startTask(taskInfo)

  const [teamsContext, setTeamsContext] = useState({})

  useEffect(() => {
    microsoftTeams.initialize()
    microsoftTeams.getContext((context) => {
      setTeamsContext(context)
    })

  }, [])

  return (
    <>
      <pre>{JSON.stringify(teamsContext, null, 2)}</pre>
      <p>{window.location.origin}</p>
      <button onClick={startTask}>Start</button>
    </>
  )
}
