import React, { useState } from 'react'
import * as microsoftTeams from '@microsoft/teams-js'

// This is the Task Module that is invoked from the `InsideIframe` component.
export const TestTaskModule = () => {
  const submitTask = () => microsoftTeams.tasks.submitTask(
    {
      task_name: 'test',
      foo: {
        bar: 'baz'
      }
    }
  )

  const [teamsContext, setTeamsContext] = useState({})

  microsoftTeams.getContext((context) => {
    setTeamsContext(context)
  })

  return (
    <>
      <pre>{JSON.stringify(teamsContext, null, 2)}</pre>
      <p>{window.location.origin}</p>
      <button onClick={submitTask}>Submit</button>
    </>
  )
}
