import React from 'react'
import * as microsoftTeams from '@microsoft/teams-js'

export const ChoosePowtoonMessageExtensionMock = () => {
  const startTask = () => {
    const taskInfo = {
      task_name: 'choosePowtoon',
      powtoon: {
        id: 'cYHLjQOUFJj',
        name: 'Company Announcement',
        description: null,
        tags: [],
        is_published: false,
        publish_url: 'https://ft2.powtoon.com/online-presentation/cYHLjQOUFJj/',
        external_url: '',
        expiry_date: null,
        last_modified: '2021-03-08T21:00:04.712677Z',
        current_version: '2021-03-08',
        category: null,
        thumb_url: 'https://powtoon-xml-new-1.s3.amazonaws.com/cYHLjQOUFJj/thumb_161523720454627.jpeg',
        is_dynamic: false,
        consumed_export: false,
        enable_rich_text: false,
        type: 'studio',
        content_source: null,
        video_url: '',
        is_locked: false,
        duration: null,
        translation_status: null
      },
    }
    microsoftTeams.tasks.submitTask(taskInfo)
  }
  return (
    <button onClick={startTask}>Choose Powtoon</button>
  )
}
