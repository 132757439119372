import React from 'react';

export const HelpTab = () => {
  return (
    <iframe id='help-and-support'
        title='Help & Support'
        src='https://support.powtoon.com/en/'>
    </iframe>
  )
}
